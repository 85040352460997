<template>
  <!-- <div class="report-container mt-3 mx-5"> -->
  <div class="report-container d-flex flex-column mt-3 mx-5" :style="format">
    <!-- <div class="report-container"> -->
    <!-- <div class="report-container mt-3"> -->
    <!-- <div v-if="isWatermarkShow" class="watermark">
        <h6 class="watermark-text">THIS DOCUMENT IS VERIFIED</h6>
      </div> -->
    <div class="d-flex align-items-center justify-content-between">
      <div class="mr-2">
        <img
          :src="institusi ? institusi.logo : `/${configSchool.logo}`"
          width="80px"
          style="max-height: 75px"
          alt
        />
      </div>
      <div class="text-center">
        <h3 class="text-uppercase font-weight-bold font-size-18 mb-1">
          {{ institusi ? institusi.nama : configSchool.name2 }}
        </h3>
        <h3 class="font-size-10">
          {{ institusi ? institusi.alamat.lokasi : configSchool.address }}
        </h3>
        <h3 class="font-size-10">
          {{ noTelp ? `Telp. ${noTelp}` : '' }}
          {{ noFax ? `Fax. ${noFax}` : '' }}
        </h3>
      </div>
      <div class="invisible">
        <img
          :src="institusi ? institusi.logo : `/${configSchool.logo}`"
          width="70px"
          alt
        />
      </div>
    </div>
    <div class="hr" />
    <div class="text-center">
      <h3 class="text-uppercase font-weight-bold font-size-12" v-if="!kumer">
        MID SEMESTER PROGRESS REPORT
      </h3>
      <h3 class="text-uppercase font-weight-bold font-size-12" v-else>
        MID SEMESTER PROGRESS REPORT
      </h3>
      <h3 class="text-uppercase font-weight-bold font-size-10 mt-1">
        academic year
        {{ dataReport.tahun_ajaran.tahun }}
      </h3>
    </div>
    <div v-if="kumer">
      <table
        class="table table-borderless font-weight-bold font-size-10 mt-2 primaryTable"
      >
        <tr>
          <td class="text-uppercase">Name</td>
          <td>:</td>
          <td style="width: 55%">
            {{ dataReport.murid.nama || '-' }}
          </td>
          <td class="text-uppercase">Number Student</td>
          <td>:</td>
          <td>{{ dataReport.murid.nis || '-' }}</td>
        </tr>
        <tr>
          <td class="text-uppercase">Class</td>
          <td>:</td>
          <td>
            {{ dataReport.murid.kelas || '-' }}
          </td>
          <td class="text-uppercase">NISN / SEMESTER</td>
          <td>:</td>
          <td>
            {{ dataReport.murid.nisn || '-' }} /
            {{ dataReport.tahun_ajaran.semester || '-' }}
          </td>
        </tr>
      </table>
      <table
        class="w-100 table table-bordered table-responsive-sm font-size-10 text-center primaryTable"
      >
        <tr>
          <td
            class="align-middle font-weight-bold"
            rowspan="2"
            style="width: 5%"
          >
            NO
          </td>
          <td
            style="width: 30%"
            class="align-middle font-weight-bold"
            rowspan="2"
          >
            SUBJECT
          </td>
          <!-- KBM -->
          <!-- <td style="width: 10%" class="align-middle font-weight-bold" rowspan="2">CLASS AVERAGE</td> -->
          <!-- Formatif ASSESSMENT -->
          <td
            style="width: 12%"
            class="align-middle font-weight-bold"
            rowspan="2"
          >
            FORMATIVE ASSESSMENT
          </td>
          <!-- KNOWLEDGE / DAILY SUMMATIVE ASSESSMENT -->
          <td class="font-weight-bold" rowspan="1" colspan="2">
            DAILY SUMMATIVE ASSESSMENT
          </td>
          <td class="align-middle font-weight-bold" rowspan="2" colspan="2">
            MID SUMMATIVE ASSESSMENT
          </td>
        </tr>
        <tr>
          <td class="font-weight-bold" style="width: 12%" rowspan="1">1</td>
          <td class="font-weight-bold" style="width: 12%" rowspan="1">2</td>
          <!-- <td class="font-weight-bold" style="width: 7%" rowspan="1">3</td>
          <td class="font-weight-bold" style="width: 7%" rowspan="1">4</td> -->
        </tr>
        <template
          class="border"
          v-for="(group, i) in dataReport.group_mata_pelajaran"
        >
          <tr
            :key="i + '-mapel group-' + group.nama_group"
            class="text-left"
            v-if="!kumer"
          >
            <td class="font-weight-bold" colspan="13">
              GROUP {{ group.nama_group }}
            </td>
          </tr>
          <tr
            v-for="(mapel, index1) in group.mata_pelajaran"
            :key="index1 + '-nama-mapel-' + mapel.nama_mata_pelajaran"
          >
            <td class="align-middle">
              {{ numberIndexKumer(index1, i, group.mata_pelajaran.length) }}
            </td>
            <td class="text-left align-middle">
              <div
                class="pl-1"
                style="padding-top: 1px; padding-bottom: 1px !important"
              >
                {{ mapel.nama_mata_pelajaran || '-' }}
              </div>
            </td>
            <!-- <td class="align-middle">
              {{
                mapel.avgClass || '-'
              }}
            </td> -->
            <td class="align-middle">
              {{ mapel.formatifAvg || '-' }}
            </td>

            <td
              v-for="(item, index) in 2"
              :key="item + 'knowledge'"
              class="align-middle"
            >
              {{ floatNumber(mapel[`written${index + 1}`]) || '-' }}
            </td>
            <td class="align-middle">
              {{ floatNumber(mapel.pts_knowledge) || '-' }}
            </td>
          </tr>
        </template>
      </table>
    </div>
    <div v-else>
      <table
        class="table table-borderless font-weight-bold font-size-10 mt-2 primaryTable"
      >
        <tr>
          <td class="text-uppercase">Name</td>
          <td>:</td>
          <td style="width: 55%">
            {{ dataReport.murid.nama || '-' }}
          </td>
          <td class="text-uppercase">Number Student</td>
          <td>:</td>
          <td>{{ dataReport.murid.nis || '-' }}</td>
        </tr>
        <tr>
          <td class="text-uppercase">Class</td>
          <td>:</td>
          <td>
            {{ dataReport.murid.kelas || '-' }}
          </td>
          <td class="text-uppercase">NISN / SEMESTER</td>
          <td>:</td>
          <td>
            {{ dataReport.murid.nisn || '-' }} /
            {{ dataReport.tahun_ajaran.semester || '-' }}
          </td>
        </tr>
      </table>
      <table
        class="w-100 table table-bordered table-responsive-sm font-size-10 text-center primaryTable"
      >
        <tr>
          <td class="align-middle font-weight-bold" rowspan="2">NO</td>
          <td
            style="width: 30%"
            class="align-middle font-weight-bold"
            rowspan="2"
          >
            SUBJECT
          </td>
          <td class="align-middle font-weight-bold" rowspan="2">KBM</td>
          <td class="font-weight-bold" rowspan="1" colspan="4">KNOWLEDGE</td>
          <td class="font-weight-bold" rowspan="1" colspan="4">SKILL</td>
          <td class="font-weight-bold" rowspan="1" colspan="2">MID TEST</td>
        </tr>
        <tr>
          <td class="font-weight-bold" style="width: 7%" rowspan="1">1</td>
          <td class="font-weight-bold" style="width: 7%" rowspan="1">2</td>
          <td class="font-weight-bold" style="width: 7%" rowspan="1">3</td>
          <td class="font-weight-bold" style="width: 7%" rowspan="1">4</td>
          <td class="font-weight-bold" style="width: 7%" rowspan="1">1</td>
          <td class="font-weight-bold" style="width: 7%" rowspan="1">2</td>
          <td class="font-weight-bold" style="width: 7%" rowspan="1">3</td>
          <td class="font-weight-bold" style="width: 7%" rowspan="1">4</td>
          <td class="font-weight-bold" style="width: 7%" rowspan="1">Know</td>
          <td class="font-weight-bold" style="width: 7%" rowspan="1">Skill</td>
        </tr>
        <template
          class="border"
          v-for="(group, i) in dataReport.group_mata_pelajaran"
        >
          <tr :key="i + '-mapel group-' + group.nama_group" class="text-left">
            <td class="font-weight-bold" colspan="13">
              GROUP {{ group.nama_group }}
            </td>
          </tr>
          <tr
            v-for="(mapel, index1) in group.mata_pelajaran"
            :key="index1 + '-nama-mapel-' + mapel.nama_mata_pelajaran"
          >
            <td class="align-middle">{{ numberIndex(index1) }}</td>
            <td class="text-left align-middle">
              <div
                class="pl-1"
                style="padding-top: 1px; padding-bottom: 1px !important"
              >
                {{ mapel.nama_mata_pelajaran || '-' }}
              </div>
            </td>
            <td class="align-middle">
              {{ mapel.kbm || '-' }}
            </td>
            <td
              v-for="(item, index) in 4"
              :key="item + 'knowledge'"
              class="align-middle"
            >
              {{ floatNumber(mapel[`kd_knowledge${index + 1}`]) || '-' }}
            </td>
            <td
              v-for="(item, index) in 4"
              :key="item + 'skill'"
              class="align-middle"
            >
              {{ floatNumber(mapel[`kd_skill${index + 1}`]) || '-' }}
            </td>
            <td class="align-middle">
              {{ floatNumber(mapel.pts_knowledge) || '-' }}
            </td>
            <td class="align-middle">
              {{ floatNumber(mapel.pts_skill) || '-' }}
            </td>
          </tr>
        </template>
      </table>
    </div>
    <table class="table table-bordered text-center font-size-10 primaryTable">
      <tr>
        <td class="align-middle font-weight-bold text-uppercase w-50">
          Total Score(s)
        </td>
        <td class="text-left" rowspan="1">
          <table class="borderless table table-borderless mb-0">
            <tr>
              <td class="w-50 invisible">1. Spiritual</td>
              <td style="width: 10px">:</td>
              <td style="width: 40px">
                {{ dataReport.total_score || '-' }}
              </td>
              <td class="invisible">days</td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
    <table class="table table-bordered text-center font-size-10 primaryTable">
      <tr>
        <td class="align-middle font-weight-bold text-uppercase w-50">
          Average Report
        </td>
        <td class="text-left" rowspan="1">
          <table class="borderless table table-borderless mb-0">
            <tr>
              <td class="w-50 invisible">1. Spiritual</td>
              <td style="width: 10px">:</td>
              <td style="width: 40px">
                {{ dataReport.average_score || '-' }}
              </td>
              <td class="invisible">days</td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
    <table class="table table-bordered text-center font-size-10 primaryTable">
      <tr>
        <td
          class="align-middle font-weight-bold text-uppercase w-50"
          rowspan="3"
        >
          Attendance
        </td>
        <td class="text-left" rowspan="1">
          <table class="borderless table table-borderless mb-0">
            <tr>
              <td class="w-50">1. Sick</td>
              <td style="width: 10px">:</td>
              <td style="width: 40px">{{ dataReport.attendance.sick }}</td>
              <td>day(s)</td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td class="text-left" rowspan="1">
          <table class="borderless table table-borderless mb-0">
            <tr>
              <td class="w-50">2. Permit</td>
              <td style="width: 10px">:</td>
              <td style="width: 40px">{{ dataReport.attendance.permit }}</td>
              <td>day(s)</td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td class="text-left" rowspan="1">
          <table class="borderless table table-borderless mb-0">
            <tr>
              <td class="w-50">3. Absent</td>
              <td style="width: 10px">:</td>
              <td style="width: 40px">{{ dataReport.attendance.absent }}</td>
              <td>day(s)</td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
    <table class="table table-bordered text-center font-size-10 primaryTable">
      <tr>
        <td class="align-middle font-weight-bold text-uppercase w-50">
          Spiritual Attitude
        </td>
        <td class="text-left" rowspan="1">
          <table class="borderless table table-borderless mb-0">
            <tr>
              <td class="w-50 invisible">1. Spiritual</td>
              <td style="width: 10px">:</td>
              <td style="width: 40px">{{ dataReport.spiritual_attitude }}</td>
              <td class="invisible">days</td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
    <table class="table table-bordered text-center font-size-10 primaryTable">
      <tr>
        <td
          class="align-middle font-weight-bold text-uppercase w-50"
          rowspan="6"
        >
          Social Attitude
        </td>
        <td class="text-left" rowspan="1">
          <table class="borderless table table-borderless mb-0">
            <tr>
              <td class="w-50">1. Honest</td>
              <td style="width: 10px">:</td>
              <td style="width: 40px">
                {{ dataReport.social_attitude.honest }}
              </td>
              <td class="invisible">days</td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td class="text-left" rowspan="1">
          <table class="borderless table table-borderless mb-0">
            <tr>
              <td class="w-50">2. Discipline</td>
              <td style="width: 10px">:</td>
              <td style="width: 40px">
                {{ dataReport.social_attitude.discipline }}
              </td>
              <td class="invisible">days</td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td class="text-left" rowspan="1">
          <table class="borderless table table-borderless mb-0">
            <tr>
              <td class="w-50">3. Politiness</td>
              <td style="width: 10px">:</td>
              <td style="width: 40px">
                {{ dataReport.social_attitude.politeness }}
              </td>
              <td class="invisible">days</td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td class="text-left" rowspan="1">
          <table class="borderless table table-borderless mb-0">
            <tr>
              <td class="w-50">4. Self Confidence</td>
              <td style="width: 10px">:</td>
              <td style="width: 40px">
                {{ dataReport.social_attitude.self_confidence }}
              </td>
              <td class="invisible">days</td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td class="text-left" rowspan="1">
          <table class="borderless table table-borderless mb-0">
            <tr>
              <td class="w-50">5. Caring</td>
              <td style="width: 10px">:</td>
              <td style="width: 40px">
                {{ dataReport.social_attitude.caring }}
              </td>
              <td class="invisible">days</td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td class="text-left" rowspan="1">
          <table class="borderless table table-borderless mb-0">
            <tr>
              <td class="w-50">6. Responsible</td>
              <td style="width: 10px">:</td>
              <td style="width: 40px">
                {{ dataReport.social_attitude.responsible }}
              </td>
              <td class="invisible">days</td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
    <table class="table table-bordered font-size-10 primaryTable">
      <tr class="text-center">
        <td class="font-weight-bold" colspan="6">
          RECAPITULATION OF REWARD AND PUNISHMENT
        </td>
      </tr>
      <tr class="text-center font-weight-bold">
        <td>REWARD</td>
        <td>
          <div class="px-2">SCORE</div>
        </td>
        <td>
          <div class="px-2">FREQUENCY</div>
        </td>
        <td>PUNISHMENT</td>
        <td>
          <div class="px-2">SCORE</div>
        </td>
        <td>
          <div class="px-2">FREQUENCY</div>
        </td>
      </tr>
      <tr
        v-for="i in rewardLength > punishmentLength
          ? rewardLength
          : punishmentLength"
        :key="i"
      >
        <td
          v-if="dataReport[`reward_${i}`]"
          :class="
            'nama' in dataReport[`reward_${i}`]
              ? ['text-center', 'align-middle']
              : null
          "
          class="w-50"
        >
          <div class="pl-2">
            {{ dataReport[`reward_${i}`].nama || '-' }}
          </div>
        </td>
        <td class="align-middle text-center" v-else>-</td>
        <td class="align-middle text-center" v-if="dataReport[`reward_${i}`]">
          {{ dataReport[`reward_${i}`].score || '-' }}
        </td>
        <td class="align-middle text-center" v-else>-</td>
        <td class="align-middle text-center" v-if="dataReport[`reward_${i}`]">
          {{ dataReport[`reward_${i}`].frequency || '-' }}
        </td>
        <td class="align-middle text-center" v-else>-</td>
        <td
          v-if="dataReport[`punishment_${i}`]"
          :class="
            'nama' in dataReport[`punishment_${i}`]
              ? ['text-center', 'align-middle']
              : null
          "
          class="w-50"
        >
          <div class="pl-2">
            {{ dataReport[`punishment_${i}`].nama || '-' }}
          </div>
        </td>
        <td class="align-middle text-center" v-else>-</td>
        <td
          class="align-middle text-center"
          v-if="dataReport[`punishment_${i}`]"
        >
          {{ dataReport[`punishment_${i}`].score || '-' }}
        </td>
        <td class="align-middle text-center" v-else>-</td>
        <td
          class="align-middle text-center"
          v-if="dataReport[`punishment_${i}`]"
        >
          {{ dataReport[`punishment_${i}`].frequency || '-' }}
        </td>
        <td class="align-middle text-center" v-else>-</td>
      </tr>
      <tr class="font-weight-bold">
        <td class="text-center" colspan="4">
          RECAPITULATION OF REWARD AND PUNISHMENT POINT
        </td>
        <td class="text-center" colspan="2">
          {{ dataReport.total_rekapitulasi_reward_punishment }}
        </td>
      </tr>
    </table>
    <!-- <div class="ttd my-2 font-size-10 d-flex align-items-center justify-content-between"> -->
    <div
      class="ttd mt-1 font-size-10 d-flex justify-content-between qrcode"
      style="margin-bottom: 30px"
    >
      <!-- <canvas id="canvas2QrCode"></canvas> -->
      <!-- {{base64QrCode}} -->
      <!-- <br />
            <small>*{{textCaption}}</small> -->
      <!-- {{base64QrCode}} -->
      <!-- {{base64QrCode}} -->
      <img
        class="qrcode-image"
        v-if="base64QrCode"
        :src="base64QrCode"
        alt="qrcode"
      />
      <div class="">
        <h3 class="text-center font-size-10 invisible">.</h3>
        <h3 class="text-center font-size-10">Parent</h3>
        <h3 class="text-center font-size-10 font-weight-bold mt-3 pt-5">
          ({{ dataReport.nama_orangtua || '-' }})
        </h3>
      </div>
      <div class="">
        <h3 class="text-center font-size-10 invisible">.</h3>
        <h3 class="text-center font-size-10">Class Teacher</h3>
        <div
          class="text-center mt-3 font-size-18"
          :class="dataReport.date_verify_walikelas ? '' : 'invisible'"
        >
          <!-- <a-icon id="iconSvg1" type="check-square" style="color: #041930;"/> -->
          <!-- <check-icon /> -->
          <img src="@/assets/checkbox.png" width="18" alt="verified" />
          <!-- <input class="checkVerification" type="checkbox" checked disabled> -->
        </div>
        <h3 class="text-center font-size-10 font-weight-bold mt-3 pt-1">
          ({{ dataReport.nama_walikelas || '-' }})
        </h3>
      </div>
      <div class="">
        <h3 style="letter-spacing: 0.025em" class="text-center font-size-10">
          Jakarta, {{ publicationDate }}
        </h3>
        <h3 class="text-center font-size-10">Principal</h3>
        <div
          class="text-center mt-3 font-size-18"
          :class="dataReport.date_verify_kepala_sekolah ? '' : 'invisible'"
        >
          <!-- <check-icon /> -->
          <img src="@/assets/checkbox.png" width="18" alt="verified" />
        </div>
        <h3 class="text-center font-size-10 font-weight-bold mt-3 pt-1">
          ({{ dataReport.nama_kepala_sekolah || '-' }})
        </h3>
      </div>
      <!-- <h3 class="text-center font-size-10 font-weight-bold mt-3 pt-5">({{dataReport.kepsek.nama ? dataReport.kepsek.nama : '-'}})</h3> -->
    </div>
    <!-- <br /> -->
    <!-- <div class="text-right">
            <small style="font-size: 8px;">*{{textCaption}}</small>
          </div> -->
    <!-- <h3 class="text-center font-size-10 font-weight-bold text-white mt-1">Lorem ipsum dolor sit amet consectetur adipisicing elit.</h3> -->
  </div>
</template>

<script>
import moment from 'moment'
import QRCode from 'qrcode'
// const CheckIcon = () => import('@/components/app/CheckIcon')
export default {
  data() {
    return {
      subjectNumber: 0,
      base64QrCode: null,
      textCaption: null,
      baseUrl: window.location.origin,
      rewardLength: 0,
      punishmentLength: 0,
    }
  },
  // components: { CheckIcon },
  props: {
    configSchool: {
      type: Object,
    },
    dataReport: {
      type: Object,
      required: true,
    },
    format: {
      type: Object,
      // required: true,
    },
    kumer: {
      type: Boolean,
      // required: true,
    },
  },
  mounted() {
    console.log('children', this.dataReport)
    this.generateBase64QrCode(`${this.dataReport.uuid}`)
    const guruWalas = this.dataReport.nama_walikelas
    const kepalaSekolah = this.dataReport.nama_kepala_sekolah
    this.textCaption = `This document was digitally signed by the principal${
      kepalaSekolah ? ` (${kepalaSekolah})` : ''
    }, and the class teacher${
      guruWalas ? ` (${guruWalas})` : ''
    }. No wet/physical signature required`

    const dataReportAsArray = Object.entries(this.dataReport) || []
    const dataReward = dataReportAsArray.filter(
      ([key]) =>
        key.includes('reward_') &&
        key !== 'total_rekapitulasi_reward_punishment',
    )
    this.rewardLength = dataReward.length
    const dataPunishment = dataReportAsArray.filter(
      ([key]) =>
        key.includes('punishment') &&
        key !== 'total_rekapitulasi_reward_punishment',
    )
    this.punishmentLength = dataPunishment.length
  },
  methods: {
    async generateBase64QrCode(uuid) {
      try {
        // console.log(this.base64IsReady)
        const link = this.baseUrl + '/public/report-preview/' + uuid
        this.base64QrCode = await QRCode.toDataURL(link)
      } catch (err) {
        console.log(err)
      }
    },
    numberIndex(number) {
      return ++number
    },
    numberIndexKumer(number, i, lengthGroupBefore) {
      if (i === 1) {
        return ++number + lengthGroupBefore + 1
      } else {
        return ++number
      }
    },
    floatNumber(number) {
      if (!number) return null
      return parseFloat(number).toFixed(2)
    },
  },
  computed: {
    today() {
      return moment().format('DD MMMM YYYY')
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
    styleContainer() {
      if (this.format) {
        return { ...this.format }
      }
      return {}
    },
    institusi() {
      return this.dataReport.institusi
    },
    noTelp() {
      let noTelp = this.institusi?.telepon
      if (this.institusi?.telepon) {
        const daerah = noTelp.slice(0, 3)
        noTelp = `(${daerah}) ${noTelp.slice(3)}`
      }
      return noTelp
    },
    noFax() {
      let noFax = this.institusi?.fax
      if (this.institusi?.fax) {
        const daerah = noFax.slice(0, 3)
        noFax = `(${daerah}) ${noFax.slice(3)}`
      }
      return noFax
    },
    publicationDate() {
      return this.dataReport.tanggal_publikasi
        ? moment(this.dataReport.tanggal_publikasi, 'YYYY-MM-DD').format(
            'DD MMMM YYYY',
          )
        : moment().format('DD MMMM YYYY')
    },
    // rewards() {
    //   let arr = []
    //   if (this.dataReport.report_reward_punishments.length) {
    //     arr = this.dataReport.report_reward_punishments.filter(rp => rp.reward_punishment.type === 'reward')
    //   }
    //   return arr
    // },
    // punishments() {
    //   let arr = []
    //   if (this.dataReport.report_reward_punishments.length) {
    //     arr = this.dataReport.report_reward_punishments.filter(rp => rp.reward_punishment.type === 'punishment')
    //   }
    //   return arr
    // },
    // loopRewardPunishment() {
    //   if (this.rewards > this.punishments) {
    //     return this.rewards
    //   } else {
    //     return this.punishments
    //   }
    // },
  },
}
</script>

<style>
.checkVerification {
  margin: 0;
}

.checkVerification:disabled {
  cursor: default;
}

.report-container .primaryTable {
  margin-bottom: 5px !important;
  position: relative;
  z-index: 1;
}

.report-container .table td {
  padding: 1px !important;
  padding-top: 0.5px !important;
  padding-bottom: 0.5px !important;
}

.report-container .hr {
  border: 0.5px solid black !important;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.table-bordered {
  border: 0.5px solid black;
}

.table-bordered td {
  border: 0.5px solid black;
}

.borderless {
  border: none !important;
}

.borderless td {
  border: none !important;
}

.ttd .qrcode-image {
  min-width: 50px;
  min-height: 50px;
  max-width: 100px;
  max-height: 100px;
}

@media (min-width: 768px) {
  .ttd .qrcode-image {
    width: 100px;
    height: 100px;
  }
}

/* .watermark {
  position: absolute;
  z-index: 10;
  display: block;
  min-height: 50%;
  min-width: 50%;
}

.watermark-text {
  padding: 20%;
  color: lightgrey;
  font-size: 100px;
  transform: rotate(300deg);
  -webkit-transform: rotate(300deg);
} */
</style>
